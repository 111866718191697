<template>
  <div class="csn-nsi" v-click-outside="clickOutsideInput">
    <button @click="toggleMobileSearchInput" class="csn-nsi-btn">
      <div class="csn-nsi-mob-glass-icon-wrapper">
        <SearchIcon
          v-if="isCasinoFive"
          class="csn-breadcrumb-icon csn-nsi-mob-glass-icon-five"
        />
        <span
          v-else
          class="casino-icon casino-icon-search csn-nsi-mob-glass-icon"
        ></span>
      </div>
    </button>

    <div
      class="csn-nsi-input-wrapper"
      :class="{
        'csn-nsi-input-wrapper-open': IS_MOBILE_SEARCH_INPUT_OPEN,
        'csn-nsi-input-wrapper-closed': IS_MOBILE_SEARCH_INPUT_OPEN === false,
      }"
    >
      <TextField
        containerClass="csn-nsi-input"
        :placeholder="t('browse_games')"
        :value="search"
        @focus="handleSearchFocus"
        @input="debounceSearch"
        @enter="handleEnterPress"
      />
      <div class="csn-nsi-glass-icon-wrapper" @click="handleSearchResult">
        <SearchIcon
          v-if="isCasinoFive"
          class="csn-breadcrumb-icon csn-nsi-glass-icon-five"
        />
        <span
          v-else
          class="casino-icon casino-icon-search csn-nsi-glass-icon"
        ></span>
      </div>
    </div>

    <div v-if="rendersSearchList" class="csn-nsi-game-list">
      <Loader v-if="isSearchPending" class="csn-nsi-game-loader" />
      <div
        v-else
        v-for="game in searchResult"
        :key="game.urlSlug"
        class="csn-nsi-game"
      >
        <div class="csn-nsi-game-image-container">
          <img
            :src="game.image"
            @error="replaceByDefault"
            alt="game image"
            class="csn-nsi-game-image"
          />
        </div>
        <div class="csn-nsi-game-data">
          <Route
            :to="{ name: gameInfoRoute, params: { slug: game.urlSlug } }"
            class="csn-nsi-game-route"
          >
            <span class="csn-nsi-game-title">
              {{ game.name }}
            </span>
          </Route>
          <div class="csn-nsi-game-button-container">
            <button
              v-if="game.buttons.play"
              class="btn casino-btn csn-game-box-wrapper-play-btn"
              @click="handlePlayClick(game.urlSlug)"
            >
              {{ t('play_now') }}
            </button>
            <button
              v-if="game.buttons.demo"
              class="btn casino-btn csn-game-box-wrapper-demo-btn"
              @click="handleDemoClick(game.urlSlug)"
            >
              {{ t('try_me') }}
            </button>
          </div>
        </div>
      </div>
      <Route
        :to="{ name: searchResultRoute, query: { search } }"
        v-if="rendersSearchTotal"
        @click.native="handleSearchBlur"
        class="csn-nsi-game-list-total-anchor"
      >
        <div class="csn-nsi-game-list-total">
          <SearchIcon
            v-if="isCasinoFive"
            class="csn-nsi-game-list-total-glass-icon-five"
          />
          <span
            v-else
            class="casino-icon casino-icon-search csn-nsi-glass-icon"
          ></span>
          {{ t('show_results_text_1') }}
          {{ SEARCH_RESULT_TOTAL }}
          {{ t('show_results_text_2') }}
        </div>
      </Route>
      <div v-if="rendersSearchEmptyList" class="csn-nsi-empty-list">
        <span class="casino-icon casino-icon-information"></span>
        {{ t('no_results') }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

import SearchIcon from '@/components/svg/SearchIcon'

import {
  NAVBAR__SEARCH_INPUT,
  UPDATE_SEARCH_RESULT_DICTIONARY,
  SEARCH_RESULT_SHORT_LIST,
  SEARCH_RESULT_TOTAL,
  Module,
  RouteName,
  IS_MOBILE_SCREEN,
  TOGGLE_MOBILE_SEARCH_INPUT,
  IS_MOBILE_SEARCH_INPUT_OPEN,
  SCREEN_WIDTH,
  DefaultImage,
  SEARCH_QUERY,
  CASINO_FIVE,
} from '@/constants'
import {
  dest,
  navigateTo,
  isEmpty,
  requestTimeout,
  cancelTimeout,
} from '@/helpers'

export default {
  name: NAVBAR__SEARCH_INPUT,
  components: {
    TextField: () => import('@/components/TextField'),
    Loader: () => import('@/components/Loader'),
    Route: () => import('@/components/Route'),
    SearchIcon,
  },
  data: () => ({
    search: '',
    isSearchPending: false,
    isSearchFocused: false,
    closeSearchInputRequest: null,
    searchTimout: null,
  }),
  computed: {
    ...mapState(Module.SEARCH_RESULT, [SEARCH_RESULT_TOTAL]),
    ...mapState(Module.MAIN, { IS_MOBILE_SEARCH_INPUT_OPEN }),
    ...mapState(Module.DEVICE, [SCREEN_WIDTH]),
    ...mapGetters({
      searchResult: dest([Module.SEARCH_RESULT, SEARCH_RESULT_SHORT_LIST]),
    }),
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    ...mapGetters({ searchQuery: dest([Module.LOCATION, SEARCH_QUERY]) }),
    t() {
      return this.$createComponentTranslator(NAVBAR__SEARCH_INPUT)
    },
    searchResultRoute: () => RouteName.SEARCH_RESULT,
    gameInfoRoute: () => RouteName.GAME_INFO,
    rendersSearchList() {
      return this.search && this.isSearchFocused
    },
    rendersSearchEmptyList() {
      return (
        this.search &&
        this.isSearchFocused &&
        !this.isSearchPending &&
        isEmpty(this.searchResult)
      )
    },
    rendersSearchTotal() {
      return !this.isSearchPending && this.SEARCH_RESULT_TOTAL > 4
    },
    isCasinoFive: () => process.env.VUE_APP_THEME === CASINO_FIVE,
  },
  watch: {
    searchQuery: {
      immediate: true,
      handler(searchQuery) {
        this.search !== searchQuery && (this.search = searchQuery)
      },
    },
  },
  methods: {
    ...mapActions(Module.SEARCH_RESULT, [UPDATE_SEARCH_RESULT_DICTIONARY]),
    ...mapMutations(Module.MAIN, [TOGGLE_MOBILE_SEARCH_INPUT]),
    async searchGame() {
      if (!this.search) {
        return
      }

      this.isSearchPending = true
      await this.UPDATE_SEARCH_RESULT_DICTIONARY({
        search: this.search,
        resetsData: true,
        category: '',
        vendor: '',
        hotFlag: '',
        newFlag: '',
        order: '',
      })
      this.isSearchPending = false
    },
    handlePlayClick(slug) {
      navigateTo({
        name: RouteName.GAME_PLAY,
        params: { slug },
      })
    },
    handleDemoClick(slug) {
      navigateTo({
        name: RouteName.GAME_DEMO,
        params: { slug },
      })
    },
    handleSearchFocus() {
      this.isSearchFocused = true
    },
    handleSearchBlur() {
      this.isSearchFocused = false
    },
    clickOutsideInput() {
      this.handleSearchBlur()

      this.SCREEN_WIDTH <= 1000 &&
        this.IS_MOBILE_SEARCH_INPUT_OPEN &&
        this.toggleMobileSearchInput()
    },
    toggleMobileSearchInput() {
      const shallCloseSearchInput = this.IS_MOBILE_SEARCH_INPUT_OPEN

      this.TOGGLE_MOBILE_SEARCH_INPUT(!this.IS_MOBILE_SEARCH_INPUT_OPEN)

      shallCloseSearchInput &&
        this.$nextTick(() => {
          this.closeSearchInputRequest = requestTimeout(
            this.clearCloseMobileSearchInput,
            400,
          )
        })
    },
    async clearCloseMobileSearchInput() {
      await this.TOGGLE_MOBILE_SEARCH_INPUT(null)
      await cancelTimeout(this.closeSearchInputRequest)
      this.closeSearchInputRequest = null
    },
    debounceSearch(value) {
      this.searchTimout && cancelTimeout(this.searchTimout)
      this.search = value
      const that = this

      this.searchTimout = requestTimeout(() => {
        that.searchGame()
      }, 400)
    },
    replaceByDefault(e) {
      e.target.src = DefaultImage[process.env.VUE_APP_THEME]
    },
    handleSearchResult() {
      const query = { ...this.$router.history.current.query }

      this.handleSearchBlur()
      this.search ? (query.search = this.search) : delete query.search
      navigateTo(
        {
          name: this.searchResultRoute,
          query,
        },
        {
          isStrict: query.search !== this.$router.history.current.query.search,
        },
      )
    },
    handleEnterPress() {
      this.handleSearchResult()
    },
  },
  unmounted() {
    this.searchTimout && cancelTimeout(this.searchTimout)
  },
}
</script>
